import React from 'react';
import Helmet from 'react-helmet';
import PageTemplate from 'templates/PageTemplate';
import ServicesList from 'components/ServicesList';

import GutPunch from 'images/404-image.jpg';

const NotFoundPage = () => (
  <PageTemplate className="errorPage">
    <Helmet title="Page Not Found" />

    <div className="container">
      <div className="errorPage--header">
        <div className="errorPage--title">
          <h1>
            Page not found
          </h1>

          <p>
            We couldn&rsquo;t find what you were looking for. Try using
            the navigation links above or explore our services below.
          </p>
        </div>

        <img src={GutPunch} alt="Comical gut punch with 404 text overlaid" width="700px" height="auto" className="errorPage--image shadow" />
      </div>

      <ServicesList />
    </div>
  </PageTemplate>
);

export default NotFoundPage;
